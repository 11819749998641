<template>
	<div>
		<FormWeb
			:id="id"
			:is-update="isUpdate"
			@createOrder="createOrder"
			@cancelForm="cancelForm"
			@submit="updateForm"
			@cancel="cancel"
			@create-request="getFormWebDetail"
			@cancel-request="getFormWebDetail"
			@updateOrder="updateOrder"
		/>
		<CustomModal
			v-if="isCancelFormModal"
			:title="$t('FormMessages.cancel.header')"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Cancel')"
			@submit-modal="sure"
			@cancel-modal="cancelModal"
		>
			<template #content>
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(sure)">
						<div class="custom">
							<div class="custom__input">
								<div class="form-group position-relative mb-0">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="form_reason"
										:rules="`requiredExport|max:${CANCEL_REASON}`"
									>
										<div class="form-group row">
											<label
												for="reason"
												class="col-12 col-xl-12 col-lg-12 col-md-12 col-form-label"
												>{{ $t("FormsField.reason") }}</label
											>
											<div :class="['col-12 col-xl-12 col-lg-12 col-md-12', classes]">
												<textarea
													id="description"
													v-model.trim="reason"
													type="text"
													rows="6"
													class="form-control remove-resize"
													:placeholder="$t('FormsField.reason')"
													autocomplete="description"
													:maxlength="CANCEL_REASON"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>
					</form>
				</ValidationObserver>
			</template>
		</CustomModal>
		<CustomModal
			v-if="isLocked"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="confirm"
			@cancel-modal="confirm"
		>
			<template #content>
				{{ lockError }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import {
	GET_FORM_WEB_DETAIL,
	CANCEL_FORM_WEB,
	LOCK_FORM,
	UNLOCK_FORM,
	UPDATE_FORM_WEB,
} from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
import { GET_TYPES_ORDER } from "../../typeOrder/store/action-types"
import { RESET_FILTERS_FORM_LIST, SET } from "../store/mutation-types"
import { FORM_TYPE_CLICK, CANCEL_REASON, STATUS_FORM } from "../../../shared/plugins/constants"
const { mapActions, mapMutations, mapState } = createNamespacedHelpers("forms")
const { mapActions: orderTypeActions } = createNamespacedHelpers("typeOrder")
export default {
	name: "UpdateFormWeb",
	components: {
		FormWeb: () => import("../components/FormWeb.vue"),
	},
	props: {
		id: {
			type: [String, Number],
			default: null,
		},
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			CANCEL_REASON,
			reason: null,
			lock: null,
			isLocked: false,
		}
	},
	computed: {
		...mapState(["isCancelFormModal", "lockError", "originalId", "formDetail"]),
	},
	watch: {
		formDetail: {
			handler(val) {
				if (!val) return

				if (val.status === STATUS_FORM.CANCELLED) {
					this.$router.push(`/forms/web/${this.id}`)
				}
			},
			deep: true,
		},
	},
	async created() {
		await this.lockForm()
		if (!this.lockError) {
			this.lock = setInterval(async () => {
				await this.lockForm()
			}, process.env.VUE_APP_INTERVAL_LOCK)
			await Promise.all([this.getFormWebDetail(), this.getOrderTypes()])
		} else {
			this.isLocked = true
			this.$store.commit("set", ["modalShow", true])
		}
	},
	async beforeDestroy() {
		this.RESET_FILTERS_FORM_LIST()
		await this.unlockForm()
		clearInterval(this.lock)
		this.isLocked = false
	},
	methods: {
		...mapActions({
			GET_FORM_WEB_DETAIL,
			CANCEL_FORM_WEB,
			LOCK_FORM,
			UNLOCK_FORM,
			UPDATE_FORM_WEB,
		}),
		...orderTypeActions({ GET_TYPES_ORDER }),
		...mapMutations({ RESET_FILTERS_FORM_LIST, SET }),
		async getFormWebDetail() {
			const params = {
				id: this.id,
			}
			await this.GET_FORM_WEB_DETAIL(params)
		},
		async getOrderTypes() {
			const params = {
				data: {},
				notLoading: true,
			}
			await this.GET_TYPES_ORDER(params)
		},
		updateForm: async function (params) {
			this.showLoading()
			let type = null
			if (params.typeClick) {
				type = params.typeClick
				delete params.typeClick
			}
			// update form
			const res = await this.UPDATE_FORM_WEB(params)
			if (res.ok) {
				switch (type) {
					case FORM_TYPE_CLICK.CREATE:
						this.createOrder()
						break
					case FORM_TYPE_CLICK.UPDATE:
						this.updateOrder()
						break
					default:
						this.gotoList()
						break
				}
			}
			this.hideLoading()
		},
		cancelForm() {
			this.SET(["isCancelFormModal", true])
			this.$store.commit("set", ["modalShow", true])
		},
		cancelModal() {
			this.SET(["isCancelFormModal", false])
			this.reason = null
		},
		gotoList() {
			this.$router.push({ name: "FormManagement" })
		},
		createOrder() {
			this.$router.push({
				path: "/orders/web/create",
				query: { formID: this.id },
			})
		},
		updateOrder() {
			this.$router.push({
				path: "/orders/web/create",
				query: {
					formID: this.id,
					update_order: true,
					original_order: this.originalId,
				},
			})
		},
		async sure() {
			const isValid = await this.$refs.form.validate()
			if (isValid) {
				const params = {
					id: parseInt(this.id),
					reason: this.reason,
				}
				const res = await this.CANCEL_FORM_WEB(params)
				this.SET(["isCancelFormModal", false])
				this.$store.commit("set", ["modalShow", false])
				this.reason = null
				if (res.ok) {
					this.$router.push(`/forms/web/${this.id}`)
				}
			}
		},
		async lockForm() {
			const params = {
				formId: this.id,
			}
			await this.LOCK_FORM(params)
		},
		async unlockForm() {
			if (!this.isLocked) {
				const params = {
					formId: this.id,
				}
				await this.UNLOCK_FORM(params)
			}
		},
		confirm() {
			this.$store.commit("set", ["modalShow", false])
			this.$router.push(`/forms`)
		},
		cancel() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped>
.custom__input {
	width: 100%;
}
</style>
